import ClientChauffeur from "../client/ClientChauffeurEngine"
import version from "../version"
import ClientIOt from "../client/ClientIot"
const resource = version + "/driver/activity"

function gets(params) {
  return ClientChauffeur("root_service.service_path_create").get(`${resource}/list/`, { params })
}

function getsElastic(params) {
  return ClientChauffeur("root_service.service_path_create").get(`${resource}/es-list/`, { params })
}

function get(id) {
  return ClientChauffeur("root_service.service_path_create").get(`${resource}/retrieve/${id}/`)
}

function getLog(params) {
  return ClientIOt("root_service.service_path_create").get(`${version}/devices_log`, {
    params
  })
}


const api = {
  gets,
  get,
  getsElastic,
  getLog
}
export default api
